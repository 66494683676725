export const vueI18n = {"fallbackLocale":"ar-eg"}
export const vueI18nLoader = false
export const locales = [{"name":"العربية","code":"ar-ma","iso":"ar-ma","file":"ar.js"},{"name":"العربية","code":"ar-ae","iso":"ar-ae","file":"ar.js"},{"name":"العربية","code":"ar-sa","iso":"ar-sa","file":"ar.js"},{"name":"العربية","code":"ar-eg","iso":"ar-eg","file":"ar.js"},{"name":"English","code":"en-ae","iso":"en-ae","file":"en.js"},{"name":"English","code":"en-sa","iso":"en-sa","file":"en.js"},{"name":"English","code":"en-eg","iso":"en-eg","file":"en.js"},{"name":"Français","code":"fr-ma","iso":"fr-ma","file":"fr.js"}]
export const defaultLocale = ''
export const defaultDirection = 'ltr'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_and_default'
export const lazy = true
export const langDir = 'locales/'
export const rootRedirect = 'ar-eg'
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const skipSettingLocaleOnNavigate = false
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DIR_KEY = 'dir'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["ar-ma","ar-ae","ar-sa","ar-eg","en-ae","en-sa","en-eg","fr-ma"]
export const trailingSlash = undefined
