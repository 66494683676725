export default function ({ $auth, app }) {
  if (app.$cookies) {
    const cookieValue = app.$cookies.get('auth._token.local')
    if ((cookieValue === false || cookieValue === 'false') && $auth.loggedIn) {
      app.$auth.logout()
      if (process.client) {
        location.reload()
      }
    }
  } else {
    console.error('Cookies are not initialized')
  }
}
