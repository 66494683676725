<template>
  <div>
    <nuxt-link :to="localePath('consultations-pending')">
      <div v-if="!clientLoaded" class="pa-3 text-lg-center">
        <i class="v-icon icon-file-document-edit-outline" />
        <p class="ma-0 pa-0 hidden-md-and-down">
          <span class="link-title">{{ $t('moderation.title') }}</span>
        </p>
      </div>

      <div class="noselect hover pa-3 text-lg-center" :class="{ 'active': menu, 'radius': isMobile }">
        <v-badge v-if="pendingQuestions > 0" :content="pendingQuestions" color="red" overlap align="center">
          <i class="v-icon icon-file-document-edit-outline" />
        </v-badge>
        <i v-else class="v-icon icon-file-document-edit-outline" />
        <p class="ma-0 pa-0 hidden-md-and-down">
          <span class="link-title">{{ $t('moderation.title') }}</span>
        </p>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'ModerationMenu',

  data () {
    return {
      menu: false,
      clientLoaded: false,
      pendingQuestions: 0
    }
  },

  async fetch () {
    try {
      const response = await this.$axios.get('/questions/unapproved')
      this.pendingQuestions = response.data.total
    } catch (error) {
      this.pendingQuestions = 0
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },

  mounted () {
    this.clientLoaded = true
  },

  methods: {
    closeMenu () {
      this.menu = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application a:hover{
  text-decoration: none;
}
.link-title {
  font-size: 85% !important;
}

.v-icon {
  color: $default-text-color;
  margin: 0 auto;
}

.hover {
  &:hover,
  &.active {
    cursor: pointer;
    position: relative;
    background: $page-background-color;
  }
}

.radius {
  border-radius: 50%;
}
</style>
