<template>
  <div
    class="new-consultation-cta-container"
    :class="{ large: size === 'large' }"
  >
    <v-btn
      v-if="!$isAMP"
      large
      class="noselect"
      color="primary"
      nuxt
      @click="startNewConsultation()"
    >
      <i class="me-2 v-icon icon-plus" />
      {{ $t('consultation.newCta') }}
    </v-btn>
    <v-btn
      v-if="$isAMP"
      large
      color="primary"
      nuxt
      class="noselect"
      :to="'https://mawadda-eg.com/redirect/discussion/register'"
    >
      <!-- // localePath({
      //   name: 'account-login',
      //   query: {
      //     redirect_uri: localePath({
      //       name: 'new',
      //       query: { source },
      //     }),
      //   },
      // }) -->
      <i class="me-2 v-icon icon-plus" />
      {{ $t('consultation.newCta') }}
    </v-btn>
  </div>
</template>

<script>
import { trackEvent } from '@/utils/tracking'

export default {
  name: 'NewConsultationCta',
  props: {
    source: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    startNewConsultation () {
      if (this.$auth.user) {
        trackEvent(
          'Question initiated',
          { source: this.source },
          this.$auth.user
        )
        this.$router.push(this.localePath('new'))
      } else {
        trackEvent('Question initiated', { source: this.source }, null)
        window.location.href = 'https://mawadda-eg.com/redirect/discussion/login'
        // this.$router.push(
        //   this.localePath({
        //     name: 'account-login',
        //     query: { redirect_uri: this.localePath('new') }
        //   })
        // )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.large {
  zoom: 120%;
  .v-btn {
    height: 37px;
    padding: 0 85px !important;
  }
}
a {
  text-decoration: none !important;
}
.mobile {
  &.new-consultation-cta-container {
    position: fixed;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, 0);
    bottom: 0;
    padding: 20px 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgba(238, 238, 238, 0),
      rgba(64, 64, 64, 0.8)
    );
  }
}
</style>
