var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-links"},[_c('ul',_vm._l((_vm.links),function(link){return _c('li',{key:link.id,staticClass:"ma-0 pa-0",class:{
        'd-none':
          (link.expert && (!_vm.$auth.user || _vm.$auth.user.role === 'user')) ||
          (!link.external &&
            link.params &&
            link.params.filter === 'urgent' &&
            !_vm.currentMarket.paymentMethods.length),
      }},[(!link.external && link.path)?_c('nuxt-link',{staticClass:"d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title",class:{
          active:
            _vm.currentPath === link.path &&
            _vm.currentParams.filter === link.params.filter,
        },attrs:{"to":_vm.localePath({ name: link.path, params: link.params })}},[_c('i',{class:`v-icon icon-${link.iconClass}`}),_vm._v("\n        "+_vm._s(link.title)+"\n      ")]):_vm._e(),_vm._v(" "),(link.external && link.path)?_c('a',{staticClass:"d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title",attrs:{"href":link.path}},[_c('i',{class:`v-icon icon-${link.iconClass}`}),_vm._v("\n        "+_vm._s(link.title)+"\n      ")]):_vm._e(),_vm._v(" "),(
          !link.external &&
            !link.path &&
            link.countries.includes(_vm.currentMarket.country)
        )?_c('span',{staticClass:"d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title"},[_c('i',{class:`v-icon icon-${link.iconClass}`}),_vm._v("\n        "+_vm._s(link.title)+"\n        "),_c('i',{staticClass:"d-inline v-icon icon-menu-down"})]):_vm._e(),_vm._v(" "),(link.subMenu)?_c('ul',{staticClass:"dropdown",attrs:{"aria-label":"submenu"}},_vm._l((link.subMenu),function(menu){return _c('span',{key:menu.id},[(menu.countries.includes(_vm.currentMarket.country))?_c('li',[_c('nuxt-link',{staticClass:"d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title",attrs:{"to":_vm.localePath({ name: menu.path, params: link.params })}},[_vm._v("\n              "+_vm._s(menu.title)+"\n            ")])],1):_vm._e()])}),0):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }