<template>
  <div class="header">
    <Snackbar
      v-if="$store.state.snackbarMsg.length"
      :message="$store.state.snackbarMsg"
    />
    <NotificationBar v-if="!$isAMP && loaded && loggedIn" />
    <v-app-bar flat height="74" class="white pa-0 ps-2 pe-2">
      <v-app-bar-nav-icon
        v-if="!$isAMP"
        :ripple="false"
        :aria-label="$t('globals.mainMenu')"
        class="hidden-lg-and-up"
        @click.stop="
          drawer = !drawer
          expandSearch = false
        "
      />
      <div
        v-if="$isAMP"
        role="button"
        on="tap:sidebar1.toggle"
        tabindex="0"
        :aria-label="$t('globals.mainMenu')"
        class="hamburger hidden-lg-and-up"
      >
        <span class="v-btn__content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="24"
            width="24"
            role="img"
            aria-hidden="true"
            class="v-icon notranslate v-icon--svg"
          >
            <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
          </svg>
        </span>
      </div>

      <v-toolbar-title class="text-center flex-grow-1 flex-lg-grow-0">
        <Logo class="desktop" />
      </v-toolbar-title>

      <v-btn
        v-if="!$isAMP && !expandSearch"
        icon
        class="hidden-lg-and-up"
        :aria-label="$t('search.title')"
        @click="toggleSearch()"
      >
        <i class="v-icon icon-25 icon-magnify" />
      </v-btn>

      <v-btn
        v-if="!$isAMP && expandSearch"
        class="hidden-lg-and-up"
        icon
        @click="toggleSearch()"
      >
        <i class="v-icon icon-close" />
      </v-btn>

      <div
        v-show="!isMobile"
        class="flex-grow-1 pe-0 pe-xl-12 hidden-md-and-down"
      >
        <Search
          v-if="!$isAMP"
          class="me-0 me-xl-12 ms-2"
          :text="search"
          :redirect="true"
        />
      </div>
      <NavLinks v-show="!isMobile" class="desktop hidden-md-and-down" />
      <AccountGuest
        v-show="loaded && !loggedIn && !isMobile"
        class="desktop hidden-md-and-down"
      />
      <AccountLoggedIn
        v-if="loaded && loggedIn && !isMobile"
        class="desktop hidden-md-and-down"
      />
      <ModerationMenu v-if="!$isAMP && loaded && loggedIn && $auth.user.role !== 'user'" />
      <NotificationsMenu v-if="!$isAMP && loaded && loggedIn" />
    </v-app-bar>
    <v-expand-transition name="fade" mode="in-out" appear>
      <div v-if="!$isAMP && expandSearch" class="search-backdrop" />
    </v-expand-transition>
    <Search
      v-if="!$isAMP && expandSearch && isMobile"
      class="hidden-lg-and-up"
      :text="search"
      :redirect="true"
      :set-search-focus="setSearchFocus"
      @searching="expandSearch = !expandSearch"
    />

    <v-navigation-drawer
      v-if="!$isAMP && drawerLoaded && isMobile"
      v-model="drawer"
      :right="$vuetify.rtl"
      app
      temporary
    >
      <div class="text-center">
        <Logo class="mt-6" />
      </div>
      <v-divider class="mt-2 mb-2" />
      <NavLinks class="mobile" />
      <v-divider class="mt-2 mb-2" />
      <NewConsultationCta class="ma-4 mt-6 mb-6" source="side-menu" />
      <v-divider class="mt-2 mb-2" />
      <AccountGuest v-if="loaded && !loggedIn" class="mobile" />
      <AccountLoggedIn v-if="loaded && loggedIn" class="mobile" />
      <v-divider class="mt-2" />
    </v-navigation-drawer>
    <div v-if="loaded && loggedIn && !$auth.user.email_confirmed">
      <a id="email-confirmation" />
      <v-alert
        v-if="!disableEmailHeader && !resendConfirmationEmailSuccess"
        type="warning"
        align="center"
        prominent
        class="mb-0"
      >
        {{ $t('generalMessages.emailConfirmationRequired') }}&nbsp;
        <v-btn color="primary" @click="resendConfirmationEmail()">
          <i
            v-if="!resendConfirmationEmailLoading"
            class="v-icon icon-shield-check-outline me-2"
          />
          <v-progress-circular
            v-if="resendConfirmationEmailLoading"
            :size="24"
            :width="3"
            class="me-2"
            indeterminate
          />
          {{ $t('generalMessages.emailConfirmationCta') }}
        </v-btn>
      </v-alert>
      <Alert
        v-if="resendConfirmationEmailSuccess"
        type="success"
        :dismissible="true"
        align="center"
        class="mb-0"
        :message="$t('generalMessages.emailConfirmationSent')"
      />
    </div>

    <amp-sidebar v-if="$isAMP" id="sidebar1" layout="nodisplay">
      <Logo />
      <v-divider />
      <NavLinks class="mobile" />
      <v-divider />
      <NewConsultationCta class="ma-4" source="side-menu" />
      <v-divider />
      <AccountGuest v-if="loaded && !loggedIn" class="mobile" />
      <AccountLoggedIn v-if="loaded && loggedIn" class="mobile" />
      <v-divider />
    </amp-sidebar>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import Search from '@/components/Search'
import NavLinks from '@/components/HeaderComponents/NavLinks'
import NotificationsMenu from '@/components/HeaderComponents/NotificationsMenu'
import ModerationMenu from '@/components/HeaderComponents/ModerationMenu'
import AccountGuest from '@/components/HeaderComponents/AccountGuest'
import AccountLoggedIn from '@/components/HeaderComponents/AccountLoggedIn'
import NewConsultationCta from '@/components/NewConsultationCta'
import Snackbar from '@/components/Snackbar'
import Alert from '@/components/Alert'
import NotificationBar from '@/components/NotificationBar'

export default {
  components: {
    Logo,
    Search,
    NavLinks,
    NotificationsMenu,
    ModerationMenu,
    AccountGuest,
    AccountLoggedIn,
    NewConsultationCta,
    Snackbar,
    Alert,
    NotificationBar
  },

  data () {
    return {
      drawer: null,
      drawerLoaded: false,
      resendConfirmationEmailLoading: false,
      resendConfirmationEmailSuccess: false,
      expandSearch: false,
      search: this.$route.query.search || '',
      setSearchFocus: false,
      currentCountry: this.$i18n.locale.substring(3, 5),
      loaded: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    disableEmailHeader () {
      return this.$route.name.includes('experts-signup')
    },
    loggedIn () {
      return this.$auth.loggedIn
    }
  },
  mounted () {
    this.drawerLoaded = true
    this.loaded = true
  },
  methods: {
    toggleSearch () {
      this.expandSearch = !this.expandSearch
      if (this.expandSearch) {
        this.$nextTick(() => {
          this.setSearchFocus = true
        })
      } else {
        this.$nextTick(() => {
          this.setSearchFocus = false
        })
      }
    },
    resendConfirmationEmail () {
      this.resendConfirmationEmailLoading = true
      this.resendConfirmationEmailError = false
      this.$axios.$post('/users/send-email-confirmation').then((response) => {
        this.resendConfirmationEmailLoading = false
        this.resendConfirmationEmailSuccess = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-alert {
  border-radius: 0 !important;
}
.v-icon {
  color: $default-text-color !important;
  &.icon-shield-check-outline {
    color: $white-color !important;
  }
}
.header {
  z-index: 8;
  border-bottom: 1px solid $border-color;
}
.v-navigation-drawer {
  position: absolute !important;
}
.logo {
  &:not(.desktop) {
    display: block;
    margin: 10px;
  }
}
.v-toolbar__title {
  padding: 0px !important;
}
.v-app-bar__nav-icon {
  &:focus,
  &:hover {
    &::before {
      opacity: 0 !important;
      transform: none !important;
    }
  }
}
.search-backdrop {
  position: absolute;
  background: white;
  z-index: 3;
  width: 100%;
  height: 100%;
}
</style>
