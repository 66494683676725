<template>
  <div class="user-menu">
    <!-- Mobile Menu -->
    <div
      class="account-links mobile hidden-sm-and-up"
    >
      <div
        v-if="showWallet"
      >
        <v-divider />
      </div>
      <nuxt-link
        class="d-block pa-2 pt-4 ma-0 link-title"
        :to="localePath('account')"
      >
        <div
          v-if="$auth.user.role === 'expert' || $auth.user.role === 'admin'"
          class="d-inline-block"
        >
          <v-img
            class="avatar d-inline-block"
            :src="userAvatar"
            :alt="$auth.user.username"
            :title="$auth.user.username"
          />
        </div>
        <i v-else class="v-icon icon-account" />
        {{ $auth.user.username }}
      </nuxt-link>
      <nuxt-link
        class="d-block pa-2 ma-0 link-title"
        :to="localePath({ name: 'consultations-filter', params: { filter: 'mine' } })"
      >
        <i class="v-icon icon-format-list-bulleted-square" />
        {{ $t('home.introMyConsultations') }}
      </nuxt-link>
      <nuxt-link
        class="d-block pa-2 ma-0 link-title"
        to=""
        @click.native="logout()"
      >
        <i class="v-icon icon-logout" />
        {{ $t('globals.logout') }}
      </nuxt-link>
    </div>

    <!-- Desktop Menu -->
    <div
      class="account-links desktop hidden-md-and-down"
    >
      <div
        v-if="!loaded"
        class="noselect ps-4 pe-4 link-title"
      >
        <i class="d-lg-block block-icon v-icon icon-account text-center" />
        {{ truncateText($auth.user.username, 7) }}
        <i class="v-icon icon-menu-down" />
      </div>
      <v-menu
        open-on-hover
        offset-y
        auto
        class="noselect"
      >
        <template #activator="{ on }">
          <div
            class="noselect ps-4 pe-4 link-title"
            v-on="on"
          >
            <div
              v-if="$auth.user.role === 'expert' || $auth.user.role === 'admin'"
              class="d-block"
            >
              <v-img
                class="avatar"
                :src="userAvatar"
                :alt="$auth.user.username"
                :title="$auth.user.username"
              />
            </div>
            <i v-else class="d-lg-block block-icon v-icon icon-account text-center" />
            {{ truncateText($auth.user.username, 7) }}
            <i class="v-icon icon-menu-down" />
          </div>
        </template>
        <v-list
          dense
        >
          <div
            v-show="$auth.user.role !== 'user' && $auth.user.expertProfile"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <nuxt-link
                    class="d-block pa-2"
                    :to="localePath({ name: 'experts-id-username', params: { id: $auth.user.id, username: $auth.user.username } })"
                  >
                    {{ $t('nav.expertProfile') }}
                  </nuxt-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link
                  class="d-block pa-2"
                  :to="localePath('account')"
                >
                  {{ $t('nav.accountSettings') }}
                </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link
                  class="d-block pa-2"
                  :to="localePath({ name: 'consultations-filter', params: { filter: 'mine' } })"
                >
                  {{ $t('home.introMyConsultations') }}
                </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <nuxt-link
                  class="d-block pa-2"
                  to=""
                  @click.native="logout()"
                >
                  {{ $t('globals.logout') }}
                </nuxt-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { truncateText } from '@/utils'

export default {
  name: 'AccountLoggedIn',
  data () {
    return {
      loaded: false,
      walletValue: 0,
      currencyCode: '',
      truncateText
    }
  },

  computed: {
    currentMarket () {
      return this.$t('markets').filter(i => i.country === this.$i18n.locale.substring(3, 5))[0]
    },
    showWallet () {
      return this.$auth.user && !this.isAMP && this.currentMarket.country === 'ma'
    },
    userAvatar () {
      return this.$auth.user.role !== 'user' && this.$auth.user.expertProfile && this.$auth.user.expertProfile.avatar ? this.$auth.user.expertProfile.avatar : '/img/avatar-placeholder.jpg'
    }
  },

  async created () {
    if (this.showWallet) {
      await this.$auth.fetchUser()
      const markets = await this.$axios.$get('/markets')
      const userMarket = markets.filter(i => i.country === this.$auth.user.country)[0]
      this.currencyCode = this.$t('currencies')[userMarket.currency.code]
      this.walletValue = (this.$auth.user.credits / userMarket.currency.credits).toFixed(2)
    }
  },

  mounted () {
    this.loaded = true
  },
  methods: {
    goToReferrerPage () {
      this.$router.push(this.localePath('invite'))
    },
    async logout () {
      // if (!window.navigator.userAgent.match(/iPhone/i) && !window.navigator.userAgent.match(/iPad/i)) {
      //   await this.$fire.messagingReady()
      //   await this.$fire.messaging.deleteToken()
      // }
      localStorage.removeItem('temp_q_title')
      localStorage.removeItem('temp_q_category')
      localStorage.removeItem('temp_q_body')
      await this.$auth.logout()
      // this.$router.push(this.localePath('index'))
      this.$store.commit('setSnackbarMsg', this.$t('generalMessages.logoutCompleted'))
      this.$store.commit('setSnackbar', true)
      window.location.href = 'https://mawadda-eg.com/redirect/discussion/logout'
    }
  }
}
</script>

<style lang="scss" scoped>
.link-title {
  font-size: 85%!important;
}
.v-icon {
  color: $default-text-color;
  margin: 0 auto;
}
a {
  font-weight: normal!important;
  &:hover {
    background: $page-background-color!important;
    text-decoration: none!important;
  }
}
</style>

<style scoped lang="scss">
.avatar {
  border-radius: 50%;
  margin: auto;
  vertical-align: middle;
  width: 24px;
  height: 24px;
}
.v-menu__content {
  max-height: 1000px!important;
}
</style>
