<template>
  <v-card
    flat
    class="search-card pa-4"
  >
    <v-form ref="form" @submit.prevent="search">
      <v-text-field
        :ref="currentRef"
        v-model="form.text"
        class="search-box"
        autocomplete="off"
        dense
        outlined
        hide-details
        :label="$t('search.title')"
      />
      <i
        class="v-icon icon-magnify search-icon grey--text"
        @click="search"
      />
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'Search',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    filter: {
      type: String,
      required: false,
      default: ''
    },
    sortingOption: {
      type: String,
      required: false,
      default: ''
    },
    redirect: {
      type: Boolean,
      required: false,
      default: true
    },
    setSearchFocus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      form: {
        text: this.text
      },
      currentRef: !this.outlined ? 'headerSearchBox' : ''
    }
  },
  watch: {
    setSearchFocus () {
      if (this.setSearchFocus) {
        this.$refs.headerSearchBox.focus()
      }
    }
  },
  methods: {
    search () {
      this.$emit('searching', this.filter, this.sortingOption, 1, this.form.text)
      if (this.redirect) {
        this.$router.push(
          this.localePath({
            name: 'consultations-filter',
            query: { search: this.form.text }
          })
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  border-radius: $standard-radius;
}
.search-card {
  z-index: 4;
}
.search-box {
  .v-text-field__slot {
    padding-right: 25px;
  }
}
.search-icon {
  position: absolute!important;
  top: 27px;
  right: 25px;
  left: unset;
}
.v-application {
  &--is-rtl {
    .search-box {
      .v-text-field__slot {
        padding-left: 25px;
        padding-right: unset;
      }
    }
    .search-icon {
      left: 25px;
      right: unset;
    }
  }
}
</style>
