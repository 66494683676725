var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nuxt-link',{staticClass:"logo mt-2 d-inline-block",class:{
    'ms-2 mb-0': _vm.isMobile && _vm.variant !== 'footer-logo',
    'footer-logo': !_vm.isMobile && _vm.variant === 'footer-logo',
    relative: _vm.variant !== 'footer-logo',
    centered: _vm.centered,
    'd-block': _vm.variant === 'footer-logo',
  },attrs:{"to":_vm.localePath({ name: 'index' })}},[(!_vm.$isAMP)?_c('img',{staticClass:"d-block",attrs:{"title":_vm.$t('home.h1', {
        market: _vm.currentMarket.countryName,
      }),"alt":"اسأل مودة  - Mawadda","src":"/img/logo-dark.svg"}}):_vm._e(),_vm._v(" "),(_vm.$isAMP)?_c('amp-img',{attrs:{"width":"151","height":"110","layout":"fixed","alt":"اسأل مودة  - Mawadda","title":_vm.$t('globals.backToHome'),"src":"/img/logo-dark.svg"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }