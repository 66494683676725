<template>
  <audio
    v-if="!$isAMP"
    id="notificationSound"
    src="/audio/notification.mp3"
    preload="auto"
  />
</template>
<script>
export default {
  name: 'NotificationsService',
  data () {
    return {
      currentMarketId: this.$t('markets').filter(
        i => i.country === this.$i18n.locale.substring(3, 5)
      )[0].id
    }
  },
  mounted () {
    if (this.$auth.loggedIn) {
      this.startNotificationsService()
    }
    this.$nextTick(() => {
      window.setInterval(() => {
        this.startNotificationsService()
      }, 10000)
    })
  },

  methods: {
    // async updateFirebaseUserToken (token) {
    //   await this.$axios.$put(`/users/${this.$auth.user.id}`, {
    //     firebase_token: token
    //   })
    // },

    // async startFirebaseListeners () {
    //   await this.$fire.messagingReady()
    //   if (!this.$fire.messaging.swRegistration) {
    //     const token = await this.$fire.messaging.getToken()
    //     this.updateFirebaseUserToken(token)
    //     this.startFirebaseOnMessageListener()
    //     this.startFirebaseTokenRefreshListener()
    //   }
    // },

    // startFirebaseOnMessageListener () {
    //   this.$fire.messaging.onMessage((payload) => {
    //     this.handleNotification(payload)
    //   })
    // },

    // startFirebaseTokenRefreshListener () {
    //   this.$fire.messaging.onTokenRefresh(async () => {
    //     const token = await this.$fire.messaging.getToken()
    //     this.updateFirebaseUserToken(token)
    //   })
    // },

    handleNotification (payload) {
      let notificationTitle = ''
      let notificationText = ''
      let notificationLink = ''

      switch (payload.data.name) {
        case 'answer:added':
          notificationTitle = this.$t('notifications.answerAddedTitle')
          notificationText = `${this.$t('notifications.answerAdded')} ${
            payload.data.data.question_title
          }`
          notificationLink =
            this.localePath({
              name: 'consultations-id-slug',
              params: {
                id: payload.data.data.question_id,
                slug: payload.data.data.question_slug ? payload.data.data.question_slug : 'default-slug'
              }
            }) + `#answer-${payload.data.data.answer_id}`
          this.sendInAppNotification(
            notificationTitle,
            notificationText,
            notificationLink
          )
          break
        case 'answer:accepted':
          notificationTitle = this.$t('notifications.answerAcceptedTitle')
          notificationText = `${this.$t('notifications.answerAccepted')} ${
            payload.data.data.question_title
          }`
          notificationLink =
            this.localePath({
              name: 'consultations-id-slug',
              params: {
                id: payload.data.data.question_id,
                slug: payload.data.data.question_slug ? payload.data.data.question_slug : 'default-slug'
              }
            }) + `#answer-${payload.data.data.answer_id}`
          this.sendInAppNotification(
            notificationTitle,
            notificationText,
            notificationLink
          )
          break
        case 'comment:added':
          notificationTitle = this.$t('notifications.commentAddedTitle')
          if (!payload.data.data.answer_id) {
            notificationText = `${this.$t(
              'notifications.commentAddedOnConsultation'
            )} ${payload.data.data.question_title}`
          } else {
            notificationText = `${this.$t(
              'notifications.commentAddedOnAnswer'
            )} ${payload.data.data.question_title}`
          }
          notificationLink =
            this.localePath({
              name:
                payload.data.data.question_type === 'case-study'
                  ? 'cases-id-slug'
                  : 'consultations-id-slug',
              params: {
                id: payload.data.data.question_id,
                slug: payload.data.data.question_slug ? payload.data.data.question_slug : 'default-slug'
              }
            }) + `#comment-${payload.data.data.comment_id}`
          this.sendInAppNotification(
            notificationTitle,
            notificationText,
            notificationLink
          )
          break
        case 'question:paid':
          notificationTitle = this.$t('notifications.questionPaidTitle')
          notificationText = `${this.$t('notifications.questionPaid')} ${
            payload.data.data.question_title
          }`
          notificationLink = this.localePath({
            name: 'consultations-id-slug',
            params: {
              id: payload.data.data.question_id,
              slug: payload.data.data.question_slug ? payload.data.data.question_slug : 'default-slug'
            }
          })
          this.sendInAppNotification(
            notificationTitle,
            notificationText,
            notificationLink
          )
          break

        case 'expert-profile:created':
          notificationTitle = this.$t('notifications.expertProfileCreated')
          notificationLink = this.localePath({
            name: 'experts-id-username',
            params: {
              id: payload.data.data.user_id,
              username: payload.data.data.username
            }
          })
          this.sendInAppNotification(notificationTitle, '', notificationLink)
          break

        case 'expert-profile:accepted':
          notificationTitle = this.$t('notifications.expertProfileAccepted')
          notificationLink = this.localePath({
            name: 'experts-id-username',
            params: {
              id: this.$auth.user.id,
              username: this.$auth.user.username
            }
          })
          this.sendInAppNotification(notificationTitle, '', notificationLink)
          break

        case 'expert-profile:refused':
          notificationTitle = this.$t('notifications.expertProfileRefused')
          notificationLink = this.localePath({
            name: 'experts-id-username',
            params: {
              id: this.$auth.user.id,
              username: this.$auth.user.username
            }
          })
          this.sendInAppNotification(notificationTitle, '', notificationLink)
          break
      }
    },

    sendInAppNotification (
      notificationTitle,
      notificationText,
      notificationLink
    ) {
      const notification = new Notification(notificationTitle, {
        body: notificationText,
        icon: `${process.env.SITE_URL}/img/apple-touch-icon.png`
      })

      notification.onclick = (event) => {
        event.preventDefault()
        window.open(`${process.env.SITE_URL}${notificationLink}`, '_blank')
      }
    },

    async startNotificationsService () {
      if (this.$auth.loggedIn) {
        if (
          !window.navigator.userAgent.match(/iPhone/i) &&
          !window.navigator.userAgent.match(/iPad/i) &&
          window.Notification &&
          window.Notification.permission === 'granted'
        ) {
          // this.startFirebaseListeners()
        }
        const notificationsResponse = await this.$axios.$get(
          '/notifications/mine?order-col=created_at&order-dir=desc&page-size=50'
        )
        if (notificationsResponse.data.length) {
          this.$store.commit('setNotificationsResponse', notificationsResponse)

          const numberOfUnseenNotifications = notificationsResponse.data.filter(
            i => i.seen === false
          ).length
          if (numberOfUnseenNotifications > 0) {
            this.$store.commit(
              'setNumberOfUnseenNotifications',
              numberOfUnseenNotifications
            )
            this.$store.commit('setShowNotificationsDot', true)
          } else {
            this.$store.commit('setShowNotificationsDot', false)
          }
        }
      }
    }
  }
}
</script>
