<template>
  <div class="nav-links">
    <ul>
      <li
        v-for="link in links"
        :key="link.id"
        class="ma-0 pa-0"
        :class="{
          'd-none':
            (link.expert && (!$auth.user || $auth.user.role === 'user')) ||
            (!link.external &&
              link.params &&
              link.params.filter === 'urgent' &&
              !currentMarket.paymentMethods.length),
        }"
      >
        <nuxt-link
          v-if="!link.external && link.path"
          class="d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title"
          :class="{
            active:
              currentPath === link.path &&
              currentParams.filter === link.params.filter,
          }"
          :to="localePath({ name: link.path, params: link.params })"
        >
          <i :class="`v-icon icon-${link.iconClass}`" />
          {{ link.title }}
        </nuxt-link>
        <a
          v-if="link.external && link.path"
          :href="link.path"
          class="d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title"
        >
          <i :class="`v-icon icon-${link.iconClass}`" />
          {{ link.title }}
        </a>
        <span
          v-if="
            !link.external &&
              !link.path &&
              link.countries.includes(currentMarket.country)
          "
          class="d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title"
        >
          <i :class="`v-icon icon-${link.iconClass}`" />
          {{ link.title }}
          <i class="d-inline v-icon icon-menu-down" />
        </span>
        <ul v-if="link.subMenu" class="dropdown" aria-label="submenu">
          <span v-for="menu in link.subMenu" :key="menu.id">
            <li v-if="menu.countries.includes(currentMarket.country)">
              <nuxt-link
                class="d-block d-lg-inline-block pa-2 pa-lg-4 ma-0 link-title"
                :to="localePath({ name: menu.path, params: link.params })"
              >
                {{ menu.title }}
              </nuxt-link>
            </li>
          </span>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavLinks',
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    links () {
      return [
        {
          id: 1,
          external: true,
          expert: false,
          path: process.env.BLOG_URL,
          title: this.$t('nav.blog'),
          iconClass: 'book-multiple'
        },
        {
          id: 3,
          external: false,
          expert: true,
          path: 'consultations-filter',
          params: { filter: 'not-answered' },
          title: this.$t('nav.notAnsweredConsultations'),
          iconClass: 'question-circle'
        }
      ]
    },
    isMobile () {
      return this.$store.state.isMobile
    },
    currentPath () {
      if (this.$route.name) {
        return this.$route.name.slice(0, -8)
      } else {
        return ''
      }
    },
    currentParams () {
      if (this.$route.params) {
        return this.$route.params
      } else {
        return ''
      }
    },
    currentMarket () {
      return this.$t('markets').filter(
        i => i.country === this.$i18n.locale.substring(3, 5)
      )[0]
    }
  },
  mounted () {
    this.loaded = true
  }
}
</script>

<style lang="scss" scoped>
.link-title {
  width: 100%;
  font-size: 85% !important;
  &:hover {
    color: $default-text-color;
  }
}
.v-icon {
  color: $default-text-color;
}

a {
  font-weight: normal !important;
  &:hover,
  &.active {
    background: $page-background-color;
    text-decoration: none !important;
  }
}

.nav-links {
  &.desktop {
    .eservices-menu {
      margin-bottom: 2px !important;
    }
    .block-icon {
      margin-bottom: 1px !important;
    }
  }
}
li .v-icon {
  text-align: center;
  display: block;
  margin: 0 auto;
}
.badge {
  position: absolute;
  top: 25px;
}
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

li {
  display: block;
  float: right;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}
.v-navigation-drawer {
  li {
    clear: both;
    float: unset;
    border-bottom: unset;
  }
  i {
    display: inline;
  }
  ul li ul {
    position: relative;
  }
}
li:hover,
li:focus-within {
  cursor: pointer;
}

li:focus-within a {
  outline: none;
}

ul li ul {
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0rem;
  left: 0;
  display: none;
  width: auto;
}

ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover,
ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
  border-bottom: 1px solid #9e9e9e38;
  white-space: nowrap;
  a:hover {
    background: #e6e6e6;
  }
}
</style>
